import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import undoable, { includeAction } from 'redux-undo';

import * as reducers from './reducers';
import * as actions from './actions';
import rootSaga from './sagas';
import reduxThunk from 'redux-thunk';
/* tslint:disable */
import environment from '../environment';
/* tslint:disable */
import old_reducers from '../reducers';

const sagaMiddleware = createSagaMiddleware();

const middleWares = [sagaMiddleware, reduxThunk];

export default createStore(
  combineReducers({
    ...reducers,
    ...old_reducers,
    slideshow: undoable(reducers.slideshow, {
      undoType: actions.UNDO_SLIDESHOW,
      redoType: actions.REDO_SLIDESHOW,
      filter: includeAction([
        actions.UPDATE_SLIDESHOW_SUCCESS,
        actions.REORDER_TRACKS,
        actions.ADD_PHOTOS_SUCCESS,
        actions.CHANGE_SLIDESHOW_PARAMETERS
      ]),
      clearHistoryType: actions.CLEAR_UNDO_REDO_HISTORY
    })
  } as any),
  { environment },
  composeWithDevTools(applyMiddleware(...middleWares))
);

sagaMiddleware.run(rootSaga);
